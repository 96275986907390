<template>
  <div class="user_music">
    <!-- 顶部功能 -->
    <div class="header-tool">
      <div class="interviewBtn" @click="showAllotGameBox">
        <img src="../../../assets/images/allotMusic.png" height="40" alt />
      </div>
      <div class="del" @click="queryUserGame">
        <img src="../../../assets/images/custom.png" height="42" alt />
      </div>
    </div>
    <!-- 音乐控制栏 -->
    <div class="play">
      <div class="music_msg">
        <div class="msg">正在播放：</div>
        <div class="musicName">
          <p>{{ musicName }}</p>
        </div>
      </div>
      <audio
        controls="controls"
        autoplay="autoplay"
        :src="musicUrl"
        type="audio/mpeg"
      ></audio>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange1"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="音乐名称"
          width="850"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="openMusic(scope.row)"
              >播放</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分配，查询模态框 -->
    <el-dialog
      title="编辑用户音乐权限"
      :visible.sync="allotGameBox"
      width="60%"
      center
      @close="userReset"
    >
      <!-- 筛选 -->
      <div class="filtrate">
        <div class="header-title">
          <p>筛选</p>
        </div>
        <div class="header-from">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            size="mini"
            ref="formInline"
          >
            <el-form-item label="部门" prop="bumen">
              <UnlimateSelect :item-data="departmentList" @input="formInline.bumen = $event[0]" />
            </el-form-item>
            <el-form-item label="姓名" prop="user">
              <el-input v-model="formInline.user"></el-input>
            </el-form-item>
            <el-form-item label="用户编号" prop="account">
              <el-input v-model="formInline.account"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit()">查询</el-button>
              <el-button @click="resetForm('formInline')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 表格 -->
      <div class="content">
        <el-table
          :data="userList"
          tooltip-effect="dark"
          style="width: 100%"
          max-height="570"
          ref="multipleTable3"
          @selection-change="handleSelectionChange2"
          stripe
          border
          class="tableBox"
          v-loading="userloading"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="account"
            label="用户编号"
            width="230"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="real_name"
            label="姓名"
            width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="department_name"
            label="部门名称"
            width="250"
            align="center"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="80" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.sex | sexFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.age | ageFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="allotGame(scope.row)"
                v-if="!isquery"
                >分配音乐</el-button
              >
              <el-button
                size="mini"
                @click="lookUserGame(scope.row)"
                v-if="isquery"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 页码 -->
      <div class="page" v-if="showUserPage">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="userpageSize"
          :page-sizes="[10, 30, 50, 100, 200, 400]"
          layout="total,sizes, prev, pager, next, jumper"
          :total="usertotal"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="allotGameBox = false">关 闭</el-button>
        <el-button type="primary" @click="allotGame" v-if="!isquery"
          >确认分配</el-button
        >
      </span>
    </el-dialog>
    <!-- 查询详情 -->
    <el-dialog
      title="用户已有的音乐"
      :visible.sync="queryInfo"
      width="40%"
      center
      @close="userInfoReset"
    >
      <div class="content">
        <el-table
          :data="userInfoData"
          tooltip-effect="dark"
          max-height="400"
          ref="multipleTable4"
          @selection-change="handleSelectionChange4"
          stripe
          border
          class="tableBox"
          v-loading="userInfoloading"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="title"
            label="游戏名称"
            width="500"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="delGmae(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="queryInfo = false">取 消</el-button>
        <el-button type="primary" @click="queryInfo = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getmusic,
  distributionsorce,
  deldistributionsorce,
  getdistributionsorce,
} from "@/api/game.js";
import { loadUserData } from "@/api/evaluate.js";
import { loadDepartmentData } from "@/api/comm.js";
import moment from "moment";
export default {
  data() {
    return {
      tableData: [],
      page: 0,
      pagesize: 15,
      totle: 0,
      musicUrl: "",
      musicName: "无",
      queryInfo: false,
      allotGameBox: false,
      multipleSelection1: [],
      multipleSelection2: [],
      multipleSelection3: [],
      userList: [],
      userpage: 1,
      userpageSize: 10,
      usertotal: 0,
      userloading: true,
      formInline: {
        bumen: "",
        user: "",
        account: "",
      },
      departmentList: [],
      showUserPage: true,
      isquery: false,
      userInfoData: [],
      userInfoloading: true,
      userId: "",
    };
  },
  created() {
    this.loadListData();
    this.loadUsers();
    // 获取所有部门 done
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
  },
  methods: {
    handleSelectionChange() {},
    // 加载列表
    loadListData() {
      let data = {
        page: this.page,
        pagesize: this.pagesize,
      };
      getmusic(data).then((res) => {
        this.tableData = res.data;
      });
    },
    // 打开音乐
    openMusic(row) {
      this.musicUrl = row.url;
      this.musicName = row.title;
    },
    // 音乐全选
    handleSelectionChange1(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection1 = p;
    },
    // 分配游戏-全选
    handleSelectionChange2(val) {
      this.multipleSelection3 = val;
      let p = [];
      this.multipleSelection3.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection3 = p;
      console.log(p);
    },
    handleSelectionChange4() {},
    // 显示分配用户模态框
    showAllotGameBox() {
      if (this.multipleSelection1.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选出，需要分配的音乐",
          type: "warning",
        });
        return;
      }
      this.isquery = false;
      this.allotGameBox = true;
    },
    // 设置当前页面最大显示
    handleSizeChange(val) {
      this.userloading = true;
      this.userpageSize = val;
      this.loadUsers();
    },
    // 页码监听
    handleCurrentChange(val) {
      this.userloading = true;
      this.userpage = val;
      this.loadUsers();
    },
    // 分配游戏 查询
    onSubmit() {
      this.userloading = true;
      this.showUserPage = false;
      this.loadUsers(1);
      this.$nextTick(() => {
        this.showUserPage = true;
      });
    },
    // 重置
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    // 分配英语-确认分配
    allotGame(row) {
      let data = {
        uid: "",
        type: 1,
        oids: this.multipleSelection1.toString(),
      };
      if (row.id) {
        data.uid = row.id.toString();
      } else {
        if (this.multipleSelection3.length <= 0) {
          this.$message({
            showClose: true,
            message: "请先勾选出，需要分配的用户",
            type: "warning",
          });
          return;
        }
        data.uid = this.multipleSelection3.toString();
      }

      distributionsorce(data).then((res) => {
        if (res.code == 400200) {
          this.$refs.multipleTable3.clearSelection();
          this.$message({
            showClose: true,
            message: "恭喜你，分配音乐成功",
            type: "success",
          });
        }
      });
    },
    // 重置用户分配游戏
    userReset() {
      this.$refs.multipleTable3.clearSelection();
    },
    // 显示 用户列表
    queryUserGame() {
      this.allotGameBox = true;
      this.isquery = true;
    },
    // 查看用户获得的英语
    lookUserGame(row) {
      this.userInfoloading = true;
      this.queryInfo = true;
      this.userId = row.id;
      let data = {
        type: 1,
        uid: row.id,
        page: 0,
        pagesize: 99999,
      };
      getdistributionsorce(data).then((res) => {
        if (res.code == 400200) {
          this.userInfoData = res.data;
          this.userInfoloading = false;
        }
      });
    },
    // 删除游戏
    delGmae(row) {
      let data = {
        uid: this.userId,
        ids: row.id,
        type: 1,
      };
      deldistributionsorce(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "恭喜你，删除成功！",
            type: "success",
          });
          let r = this.userInfoData.filter((res, k) => {
            return res.id != row.id;
          });
          this.userInfoData = r;
        }
      });
    },
    // 重置游戏拥有模态框
    userInfoReset() {
      this.userInfoData = [];
    },
    //获取所有用户
    loadUsers(val) {
      let data = {
        page: this.userpage,
        pagesize: this.userpageSize,
        account: this.formInline.account,
        real_name: this.formInline.user,
        department_id: this.formInline.bumen,
      };
      // 发送数据
      loadUserData(data).then((res) => {
        if (res.code == 400200) {
          this.userList = res.data;
          this.usertotal = res.count;
          this.userloading = false;
        }
      });
    },
  },
  filters: {
    // 性别过滤器
    sexFilter(val) {
      return val == 0 ? "女" : "男";
    },
    // 年龄过滤器
    ageFilter(val) {
      let text = moment(val, "YYYY-MM-DD").fromNow();
      let age = parseInt(text, 10);
      if (isNaN(age)) {
        age = "0";
      }
      return age;
    },
  },
};
</script>
<style lang="less">
.user_music {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    .interviewBtn {
      width: 130px;
      margin-right: 20px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  // 音乐
  .play {
    // display: flex;
    .music_msg {
      font-weight: 700;
      margin-bottom: 10px;
      color: rgb(3, 37, 35);
      display: flex;
      .musicName {
        overflow: hidden;
        p {
          display: inline-block;
        }
      }
    }
    audio {
      height: 30px;
      border-radius: 0;
      background: rgb(241, 243, 244);
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  .el-dialog__wrapper {
    //   表格
    .content {
      position: relative;
      height: calc(~"100% - 320px");
      min-height: 00px;
      margin-top: 20px;
      color: rgb(4, 87, 102);
      th {
        color: rgb(4, 87, 102);
        padding: 0;
        height: 40px;
        line-height: 40px;
        background: rgb(217, 242, 247);
      }
      td {
        padding: 0;
        height: 35px;
        line-height: 35px;
      }
      tr.el-table__row--striped td {
        background: rgb(234, 246, 248);
      }
      .el-button + .el-button {
        margin-left: 0px;
      }
      .el-button--mini {
        padding: 4px 4px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 14px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
      .no {
        color: green;
      }
      .off {
        color: red;
      }
      .el-input__inner {
        width: 50px;
        padding: 5px;
        text-align: center;
      }
    }
    // 页码
    .page {
      // .el-pagination {
      //   position: absolute;
      //   bottom: 0;
      //   right: 0;
      // }
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
    // 筛选
    .filtrate {
      display: flex;
      width: 100%;
      height: 50px;
      background: rgb(217, 242, 247);
      margin-top: 20px;
      .header-title {
        color: rgb(4, 87, 102);
        width: 56px;
        text-align: center;
        border-right: 1px solid rgb(127, 222, 243);
      }
      p {
        line-height: 50px;
        font-size: 14px;
      }
      .header-from {
        padding-left: 10px;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 120px;
          padding-right: 20px;
        }
        .el-form-item {
          margin-bottom: 0;
        }
        .el-form-item__label {
          color: rgb(4, 87, 102);
        }
        .el-input__icon {
          width: 15px;
        }
      }
    }
  }
}
</style>
